.App {
  text-align: center;
}

.App-logo {
  width: 135px;
  pointer-events: none;
}
.Pay-logo {
  height: 50px;
  pointer-events: none;
}
.PayFooter-logo {
  width: 60px;
  pointer-events: none;
}

h1,h2 {
  color: #253370;
}
.topHeader {
  font-size: small;
  background-color: #e0e0e0;
}
.fs-small {
  font-size: small;
}
.Latpay-title {
  color: #253370;
} 
.monoSpace {
  font-family: 'Courier New', Courier, monospace;
}
.Latpay-footer {
  background-color: #253370;
  color: #ffffff;
  text-align: left;
}
.Latpay-copyright {
  background-color: #122051;
  color: #eeeeee;
  text-align: center;
  font-size: smaller;
}


.App-header {
  color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: white;
}

.clickable {
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.infoNote {
  color: #888888;
}
.form-check label {
  font-size: large;
}
.inputStyle {
  padding: 10px;
  text-align: center;
  font-size: larger;
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: #253370;
}

.btn-indigo {
  background-color: #253370;
  color: #ffffff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #253370;
  --bs-btn-hover-border-color: #253370;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #253370;
  --bs-btn-active-border-color: #253370;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #cccccc;
  --bs-btn-disabled-border-color: #cccccc;  
}

.btn-mulberry {
  background-color: #B7248F;
  color: #ffffff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #B7248F;
  --bs-btn-hover-border-color: #B7248F;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #B7248F;
  --bs-btn-active-border-color: #B7248F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #cccccc;
  --bs-btn-disabled-border-color: #cccccc;  
}

.btn-black {
  background-color: #111111;
  color: #ffffff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #111111;
  --bs-btn-hover-border-color: #111111;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #111111;
  --bs-btn-active-border-color: #111111;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #cccccc;
  --bs-btn-disabled-border-color: #cccccc;  
}

.carousel li.slide>div {
  height: 30vw;
  max-height: 400px;
  min-height: 200px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}

.licenceImg {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #eee;
}

/* POS */
.pos {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  /*background-color: #253370;*/
  /*background-image: url(images/bgImage.png);*/
  height: 100vh;
}
/*#253370
#016597*/
.payContainer {
  /*margin-top: 70px;  */
  margin-bottom: 100px;  
}
.paytop {
  height:66px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.payleft {
  height:50px;
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
}
.payscreen {
  max-width:300px;
}
.payColor {
  color: #444444;
}
.qtyBtns {
  white-space: nowrap;
}
/* GOOGLE PAY */
.App .gpay-card-info-iframe {
  margin-left: 2px;
  margin-right: 2px;
  /*width: calc(100% - 4px);
  max-width:300px;*/
  text-align: center;
  height: 40px;
}

/* APPLE PAY */
.apple-pay-button {
	height: 40px;
	display: inline-block;
	-webkit-appearance: -apple-pay-button;
	cursor: pointer;
  margin-left: 2px;
  margin-right: 2px;
  width: calc(100% - 4px);
  max-width:244px;
}

.apple-pay-button-with-text>* {
	display: none;
}

.apple-pay-button-black-with-text {
	-apple-pay-button-style: black;
}

.apple-pay-button-white-with-text {
	-apple-pay-button-style: white;
}

.apple-pay-button-white-with-line-with-text {
	-apple-pay-button-style: white-outline;
}

.apple-pay-button-text-book {
	-apple-pay-button-type: book;
}

.apple-pay-button-text-buy {
	-apple-pay-button-type: buy;
}

.apple-pay-button-text-check-out {
	-apple-pay-button-type: check-out;
}

.apple-pay-button-text-donate {
	-apple-pay-button-type: donate;
}

.form-check-input:checked {
  background-color: #B7248F;
  border-color: #B7248F;
}

h5 .form-check-input:checked {
  background-color: #253370;
  border-color: #253370;
}

/* For mobile devices */

